.cattle {
  background-color: #f1f5f9;
  @include devices(mobile) {
    margin-bottom: 3rem;
  }

  margin-bottom: 12rem;

  &__breadcrumb {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    max-width: 80vw;
    margin: 0 auto;
    padding-top: 3.2rem;

    color: #2cac4c;
    a {
      text-decoration: none;
      color: #808080;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__container {
    display: grid;

    grid-template-columns: 25% 75%;
    margin: 0 auto;
    margin-top: 1.6rem;
    column-gap: 5rem;
    max-width: 80vw;

    @include devices(mobile) {
      max-width: 95vw;
      display: block;
      margin: 0 auto;
    }
  }

  &__filter {
    &-container {
      @include devices(mobile) {
        display: none;
      }
    }
    &--header {
      display: flex;
      background-color: #2cac4c;
      border-radius: 12px 12px 0px 0px;
      padding: 2.7rem 2.4rem;
      color: #ffffff;
      font-size: 2rem;
      line-height: 2.3rem;

      @include devices(mobile) {
        display: none;
      }

      img {
        margin-right: 1.6rem;
      }
    }
    &--applied {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 1rem;
      column-gap: 1rem;
      margin-bottom: 4rem;
      @include devices(mobile) {
        margin-bottom: 1rem;
      }
    }
    &--clear {
      border: none;
      background-color: transparent;
      cursor: pointer;

      font-size: 20px;
      line-height: 23px;

      /* Primary/Blue1 */

      color: #034ea2;
    }
    &--tags {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(44, 172, 76, 0.12);
      cursor: pointer;
      padding: 1rem;
      border: 0.4px solid #2cac4c;
      border-radius: 2px;
      // font
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      /* Primary/Black */

      color: #333333;
    }
    &--lable {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
    }

    &--accordian {
      background: #ffffff;
      border: 0;

      width: 100%;
      padding: 2.4rem;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: left;
      cursor: pointer;
      transition: 0.4s;

      &:after {
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        color: #034ea2;
        float: right;
        margin-left: 5px;
      }
    }

    &--panel {
      transition: all 0.2s ease-out;
      padding: 2.4rem;
      padding-top: 0;

      font-size: 1.6rem;
      line-height: 2rem;
      background-color: #ffffff;
      border-bottom: 2px solid #f1f5f9;

      input {
        margin-right: 8px;
        // accent-color: #2cac4c;

        width: 2rem;
        height: 2rem;

        &:checked {
          appearance: none;
          -webkit-appearance: none;

          /* For a circular appearance we need a border-radius. */
          border-radius: 50%;

          /* The background will be the radio dot's color. */
          background: #2cac4c;

          /* The border will be the spacing between the dot and the outer circle */
          border: 3px solid #fff;

          /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
          box-shadow: 0 0 0 1px #2cac4c;
        }
      }

      &--item {
        padding: 8px;

        border: 0.8px solid #e6e6e6;
        border-radius: 2px;
        cursor: pointer;

        label {
          display: flex;
          cursor: pointer;
        }

        &--active {
          background: rgba(44, 172, 76, 0.12);
          /* Primary/Green1 */

          border: 0.4px solid #2cac4c;
        }
      }
    }

    &--row {
      display: flex;
      flex-wrap: wrap;

      gap: 0.8rem;
    }

    &--col {
      gap: 0.8rem;
      display: flex;
      flex-direction: column;
    }
  }

  &__category {
    margin-bottom: 6.4rem;
  }

  &__title {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.3rem;
    margin-bottom: 2.4rem;

    @include devices(mobile) {
      padding-top: 1.6rem;
    }

    &::before {
      display: inline-block;
      content: '';
      border-top: 2px solid #2cac4c;
      width: 3.6rem;
      margin-right: 0.8rem;
      transform: translateY(-0.5rem);
    }

    color: #2cac4c;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include devices(mobile) {
      justify-content: center;
    }
  }
}
