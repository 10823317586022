.feed {
  background-color: #f1f5f9;
  position: relative; 
  z-index: 0;
  @include devices(mobile) {
    padding-top: 1.6rem;
  }

&__stick {
  @include devices(mobile) {
    position: sticky;
    top: 0;
    height: 8vh;
    overflow-y: auto;
    background-color: #F1F5F9;
  }
}

  
  &__banners {
    display: flex;
    align-items: center;
    margin-bottom: 3.6rem;
    min-width: 57vw;

    transform: translateX(-3.2rem);

    @include devices(mobile) {
      transform: translateX(0);
      margin-bottom: 1.6rem;
      margin-top: 1.6rem;
    }
  }

  &__banner {
    min-width: 27vw;
    height: 16.8rem;
    background-color: bisque;
    border-radius: 8px;
    margin-right: 8px;

    @include devices(mobile) {
      min-width: 77vw;
    }

    &-container {
      display: flex;
      overflow: hidden;
    }

    &--left {
      margin-right: 1.6rem;
    }

    &--right {
      margin-left: 1.6rem;
    }
  }

  &__breadcrumb {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    max-width: 80vw;
    margin: 0 auto;
    padding-top: 3.2rem;
    a {
      color: #808080;
      text-decoration: none;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__container {
    display: grid;

    grid-template-columns: 25% 75%;
    margin: 0 auto;
    margin-top: 1.6rem;
    column-gap: 5rem;
    max-width: 80vw;

    @include devices(mobile) {
      max-width: 95vw;
      display: block;
      margin: 0 auto;
    }
  }

  &__filter {
    &-mob {
    display: none;
    max-width: 80vw;
    margin-right: 0.8rem;
    margin: 0 auto;

      a {
        text-decoration: none;

        color: black;
      }

      @include devices(mobile) {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
     

       
        &--lable {
          white-space: nowrap;  
          padding: 1.2rem 1.6rem;  
          background-color: white;
          border-radius: 8px;   
          border: 1px solid #e6e6e6;
          text-align: center;
          margin-right: 0.8rem;
          cursor: pointer;

          p {
            font-weight: 700;
           font-size: 2rem;  
          line-height: 2.4rem;  
          }
        }
      }
    }

    &-container {
      position: relative;
      @include devices(mobile) {
        display: none;
      }
    }
    &-subcontainer {
      position: sticky;
      top: 2rem;
    }
    &--header {
      display: flex;
      background-color: #2cac4c;
      border-radius: 12px 12px 0px 0px;
      padding: 2.7rem 2.4rem;
      color: #ffffff;
      font-size: 2rem;
      line-height: 2.3rem;
      img {
        margin-right: 1.6rem;
      }
    }
    &--lable {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
    }
    &--active {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;

      color: #2cac4c;
    }
    &--category {
      padding: 2.4rem;
      border: 2px solid #f1f5f9;
      border-top: 0;
      background-color: #ffffff;

      a {
        text-decoration: none;
        color: #333333;
        display: flex;
      }
    }
  }

  &__category {
    margin-bottom: 6.4rem;
  }

  &__title {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.3rem;
    margin-bottom: 2.4rem;
    text-transform: uppercase;
    @include devices(mobile) {
      padding-top: 1.6rem;
    }

    &::before {
      display: inline-block;
      content: '';
      border-top: 2px solid #2cac4c;
      width: 3.6rem;
      margin-right: 0.8rem;
      transform: translateY(-0.5rem);
    }

    color: #2cac4c;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    @include devices(mobile) {
      justify-content: center;
    }
  }

  &__emptyBg {
    width: 100%;
    height: 31vh;
    background-image: url('../../assets/EmptyStateCattle/EmptyStateBg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__notFound {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    row-gap: 2rem;
    &--text {
      font-family: 'Noto Sans Devanagari';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 37px;
      color: #333333;
    }
    &--button {
      padding: 1rem 2rem;
      /* Primary/Blue1 */

      background: #034ea2;
      /* Primary/Blue1 */

      border: 1px solid #034ea2;
      border-radius: 4px;
      font-family: 'Noto Sans Devanagari';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      cursor: pointer;
      /* Primary/White */

      color: #ffffff;
    }
  }
  &__card {
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

.green {
  color: #2cac4c;
}

.seperator {
  display: flex;
  height: 30%;
  height: 55%;
  flex-direction: column;
  justify-content: space-between;
}
.feed__mobilefilters {
  
  display: none;
  @include devices(mobile) {
    display: block;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    background: #f0f5f9;
    z-index: 1;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 4%;
    select {
      background: #ffffff;
      border: 0.4px solid #e6e6e6;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      width: 48%;
      padding: 1rem 0 1rem 1rem;
      border-right: 1rem transparent solid;
      font-family: 'Noto Sans Devanagari';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;

      /* Primary/Grey Text */

      color: #808080;
    }
  }
}
