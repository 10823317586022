.feedD {
  max-width: 80vw;
  margin: 0 auto;

  @include devices(mobile) {
    max-width: 90vw;
  }

  &__breadcrumb {
    color: #2cac4c;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    max-width: 80vw;
    margin: 0 auto;
    padding-top: 3.2rem;

    a {
      color: #808080;
      text-decoration: none;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__container {
    display: flex;

    margin-top: 3.2rem;
    margin-bottom: 12rem;

    @include devices(mobile) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__images {
    width: 30%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include devices(mobile) {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 300px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include devices(mobile) {
        height: 30rem;
      }
    }

    padding: 1rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    @include devices(mobile) {
      padding: 0;
    }
  }

  &__thumbnails {
    display: flex;
    margin-top: 1.6rem;
    margin-bottom: 1.2rem;
  }

  &__thumbnail {
    width: 120px;
    height: 120px;
    margin-right: 1.2rem;
    box-sizing: border-box;
    cursor: pointer;


    @include devices(mobile) {
      margin-right: 0.8rem;
      padding: 0.4rem 0rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include devices(mobile) {
        height: 12rem;
      }
    }

    border: 1px solid #e6e6e6;
    // filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
    border-radius: 4px;
  }

  &__details {
    width: 70%;
    margin-left: 2rem;
    box-sizing: border-box;

    @include devices(mobile) {
      width: 100%;
      margin-top: 2rem;
      margin: 0 auto;
    }
  }

  &__caption {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 24px;
    line-height: 133.33%;
    /* or 37px */

    /* Primary/Grey Text */

    color: #808080;

    @include devices(mobile) {
      font-size: 1.6rem;
      line-height: 2.4rem;

      img {
        width: 129px;
        height: 35px;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 133.33%;
    /* or 48px */

    /* Primary/Black */

    color: #333333;

    max-width: 70%;

    @include devices(mobile) {
      max-width: 100%;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__features {
    display: flex;
    margin-top: 1.2rem;
  }

  &__feature {
    padding: 0.4rem 1.2rem;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #034ea2;
    margin-right: 1.2rem;

    border: 0.8px solid #034ea2;
    border-radius: 4px;

    margin-bottom: 2.4rem;

    display: flex;

    p {
      margin-right: 0.4rem;
    }

    @include devices(mobile) {
      padding: 0.4rem 1.2rem;
      margin-bottom: 1rem;
      font-size: 1.6rem;
      line-height: 2.1rem;

      flex-direction: column;
    }
  }

  &__price {
    display: flex;
    align-items: flex-end;

    h1 {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.7rem;

      @include devices(mobile) {
        font-size: 1.6rem;
        line-height: 24px;
      }
    }

    p {
      font-size: 1.8rem;

      @include devices(mobile) {
        font-size: 1.2rem;
        line-height: 24px;
      }
    }
  }

  &__mrp {
    display: flex;
    font-size: 2.4rem;
    line-height: 3.1rem;
    margin-bottom: 2.4rem;

    @include devices(mobile) {
      font-size: 1.4rem;
      line-height: 24px;

      margin-bottom: 1rem;
    }

    &--crossed {
      text-decoration: line-through;
      text-decoration-color: red;
      text-decoration-thickness: 1.5px;
    }

    &--saving {
      color: #2cac4c;
      margin-left: 0.8rem;
    }
  }

  &__cta {
    display: flex;

    @include devices(mobile) {
      // flex-direction: column;
      // width: 100%;
    }

    &--free-delivery {
      margin-left: 1.8rem;

      display: flex;
      align-items: center;

      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      flex-grow: 1;

      img {
        width: 4rem;
        margin-right: 0.8rem;
      }

      @include devices(mobile) {
        font-size: 1.2rem;

        img {
          width: 2rem;
        }
      }
    }
  }

  &__varieties {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 1.6rem;

    @include devices(mobile) {

      margin-top: 2.2rem;
      margin-bottom: 2.2rem;
    }
  }

  &__variety {
    border: 1px solid #e6e6e6;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-right: 1.2rem;

    &--header {
      display: flex;
      justify-content: space-between;
      padding: 0.8rem;

      font-weight: 500;
      font-size: 20px;
      line-height: 33px;

      @include devices(mobile) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    &--price {
      display: flex;
      align-items: flex-end;
      padding: 0 0.8rem;
      padding-top: 0.8rem;

      @include devices(mobile) {
        align-items: stretch;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;

        @include devices(mobile) {
          font-size: 1.8rem;
          line-height: 2rem;
        }
      }

      p {
        font-size: 1.4rem;
      }
    }

    &--mrp {
      display: flex;
      font-size: 1.6rem;
      line-height: 1.9rem;
      padding: 0 0.8rem;
      padding-bottom: 0.8rem;

      @include devices(mobile) {
        font-size: 1.4rem;
      }

      &--crossed {
        text-decoration: line-through;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
      }

      &--saving {
        color: #2cac4c;
        margin-left: 0.8rem;
      }
    }

    &--selected {
      border: 1px solid #2cac4c;

      .feedD__variety--header {
        background: rgba(44, 172, 76, 0.4);
      }
    }
  }

  &__info {
    display: flex;
    padding: 1.6rem 1.8rem;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    margin-bottom: 1.2rem;

    p {
      font-size: 20px;
      line-height: 26px;
      margin-top: 0.6rem;

      @include devices(mobile) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    img {
      width: 4rem;
      margin-right: 1.2rem;
    }
  }

  &__benefits {
    display: flex;
    margin-top: 2.4rem;
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin-right: 6rem;
    text-align: center;

    @include devices(mobile) {
      margin-right: 0;
    }

    img {
      font-size: 18px;
      line-height: 22px;
      width: 60px;
      height: 60px;

      @include devices(mobile) {
        width: 3rem;
        height: 2.5rem;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 1.2rem;

      @include devices(mobile) {
        font-size: 1.4rem;
      }
    }
  }


  &__infoGraphic {
    margin-top: 4rem;

    img {
      width: 70%;

      @include devices(mobile) {
        align-items: center;
      }

    }

    @include devices(mobile) {}
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  z-index: 1;
}


.content-container {
  max-width: 100%;
  margin-bottom: 40px;
  position: relative;


  @include devices(mobile) {
    display: flex;
    justify-content: center;
    img{
      width: auto;
      border-radius: 5px;
    }

  }
}

.content-container video {
  width: 70%;
  height: auto;
  border-radius: 5px;

  
  @media screen and (min-width: 768px) {
    width: 70%; 
  }


  @media screen and (max-width: 767px) {
    width: auto;
  }
}

.play-button {
  position: absolute;
  top: 45%;
  left: 36%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  @include devices(mobile){

    top: 40%;
    left: 50%;

  }
}

.play-button::after {
  content: '';
  position: absolute;
  top: 44%;
  left: 55%;
  transform: translate(-50%, -35%) rotate(-90deg);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #333;
}

// @media screen and (max-width: 767px) {
//   .play-button::after {
//     position: absolute;
//     top: 45%;
//     left: 36%;
//     transform: translate(-50%, -50%);
//     width: 50px;
//     height: 50px;
//     background-color: rgba(255, 255, 255, 0.7);
//     border: none;
//     border-radius: 50%;
//     cursor: pointer;
//   }
// }

.feedD__info-text p {
  margin: 10px 0;
  font-size: 2.9rem;
  font-weight: 500;

}

.straight-line::before {
  content: "";
  display: inline-block;
  border-bottom: 3px solid #2cac4c;
  width: 50px;
  margin-right: 10px;
  vertical-align: middle;
}


.feedD__info-buttons {

  // display: none;
  @include devices(mobile) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.feedD__info-buttons button {
  background-color: #fcfcfc;
  color: black;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

}


.feedD__info-buttons button.active {
  background-color: #034EA2;
  color: white;
  border-radius: 5px;
}

.feedD__infoGraphic--composition {
  max-width: 100%;
  height: auto;

  @include devices(mobile) {}
}

.feedD__infoGraphic--dosage {
  max-width: 100%;
  height: auto;

  @include devices(mobile) {}
}

.feedD__infoGraphic--benefits {
  max-width: 100%;
  height: auto;

  @include devices(mobile) {}
}

.tab-content {
  display: none;
 
}

.tab-content.active {
  display: block;
 
}