.youtube {
  background: url('../../assets/aboutus_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @include devices(mobile) {
    background-position: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 75vw;
    margin: 0 auto;
    padding-top: 10rem;
    padding-bottom: 6rem;

    @include devices(mobile) {
      max-width: 90vw;
      flex-direction: column;
      padding-top: 3.6rem;
      padding-bottom: 2.4rem;
    }
  }

  &__heading {
    &--subheading {
      &::after {
        border: 1px solid #ffffff;
      }
    }
  }

  &__cta {
    margin-top: 1.6rem;
  }

  &__carousel {
    display: flex;
    align-items: center;
    max-width: 92vw;
    margin: 0 auto;
    padding-bottom: 10rem;

    @include devices(mobile) {
      padding-bottom: 2.4rem;
    }
  }

  &__button {
    width: 5.2rem;
    height: 5.2rem;
    background-color: $color-white;
    border-radius: 50%;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    @include devices(mobile) {
      display: none;
    }

    &--right {
      img {
        // position: relative;
        // top: 3%;
        // right: -4%;
        padding: 30%;
        margin: 0 auto;
      }

      margin-left: 3.2rem;
    }

    &--left {
      img {
        // position: relative;
        // top: 3%;
        // right: 5%;
        padding: 30%;
      }

      margin-right: 3.2rem;
    }

    &--mobile {
      width: 5.2rem;
      height: 5.2rem;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      margin-right: 2.4rem;

      img {
        position: relative;
        margin: 0 auto;
      }
    }
  }

  &__buttons-mobile {
    display: none;

    @include devices(mobile) {
      display: flex;
      justify-content: center;
      padding-bottom: 3.6rem;
    }
  }

  &__slide {
    @include devices(mobile) {
      padding-left: 2rem;
    }

    &--image {
      background-image: url('../../assets/media-bg.svg');
      background-repeat: no-repeat;
      padding: 0 3rem 3rem 0;
      background-position: 100% 51%;

      @include devices(mobile) {
        background-size: 91% 81%;
        background-position: 2rem 5rem;
      }
    }

    &--text {
      font-size: 2.4rem;
      line-height: 133%;
      color: $color-white;
    }
  }
}

.carousel__youtube {

  & .slider {
    min-width: 30.5%;
    max-width: 30.5%;
    margin-right: 4.8rem;

    @include devices(mobile) {
      min-width: 97%;
    }
  }

  & .carousel-slider {
    display: flex;
    align-items: center;
  }
}