*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  // color: #333333;
}

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;500;600&family=Roboto:wght@400;500&display=swap');

body {
  // move to base>typography
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $primary-black;
  // padding: 3rem;

  box-sizing: border-box;
}

.hindi {
  font-family: 'roboto';
  font-style: normal;
}
.green {
  color: #2cac4c;
}

.subheading {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.1rem;

  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    border-top: 2px solid #2cac4c;
    width: min(10vw, 5rem);
    margin-right: 0.8rem;
    transform: translateY(-0.5rem);
    position: relative;
    z-index: -1;
  }

  @include devices(mobile) {
    font-size: 1.8rem;
    line-height: 2.1rem;
    text-align: center;
    &::after {
      display: inline-block;
      content: '';
      border-top: 2px solid #2cac4c;
      width: min(10vw, 5rem);
      margin-left: 0.8rem;
      transform: translateY(-0.5rem);
      position: relative;
      z-index: -1;
    }

    // &::after {
    //   width: 4.8rem;
    // }
  }
}

.heading {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4.4rem;
  margin-top: 1.6rem;
  color: #333333;

  @include devices(mobile) {
    text-align: center;
    font-size: 1.9rem;
    line-height: 3rem;
    margin-top: 0.8rem;
  }
}

.white {
  color: #ffffff;

  &::before {
    border-top: 2px solid #ffffff;
  }

  &::after {
    border-top: 2px solid #ffffff;
  }
}

.terms--root {
  padding: 5rem 15vw;

  @include devices(mobile) {
    padding: 2rem 3rem;
  }

  h1 {
    text-align: center;
    font-size: 3.6rem;
    @include devices(mobile) {
      font-size: 2.4rem;
    }
  }

  p {
    font-size: 1.6rem;
  }
  // .hindi {
  //   margin-bottom: 2rem;
  // }

  p strong {
    font-size: 1.8rem;
    margin-top: 1.2rem;
  }
}

.notFound {
  background: rgba(176, 0, 32, 0.1);
  font-weight: 700;
  font-size: 32px;
  line-height: 133.3%;
  text-align: center;
  padding: 2.4rem 0;
  margin-bottom: 3rem;
}
.margin-left-20 {
  margin-left: 20px;
}
