.feedCard {
  &__card {
    min-width: 33%;
    padding: 1.2rem 1.6rem;
    background-color: #ffffff;
    border-radius: 0.8rem;
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;

    @include devices(mobile) {
      max-width: 95vw;
      margin-right: 0;
    }

    &--images {
      height: 11.5rem;

      display: flex;
      justify-content: space-around;
      // flex-grow: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }

    &--image-col1 {
      img {
        width: 14rem;
      }
      // margin-right: 2.1rem;

      // width: 20rem;
      // border: 1px solid;

      // img {
      //   background-color: #034ea2;
      //   object-fit: cover;
      // }
    }

    &--image-col2 {
      img {
        width: 7.7rem;
      }
    }
  }

  &__labels {
    width: 40rem;
    @include devices(mobile){
      width: 35rem;
    }
    &--sub {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 1.2rem;

      color: #808080;
    }

    &--main {
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 700;
      color: #333333;

      @include devices(mobile) {
        width: 75%;
      }
    }
  }

  &__feats {
    display: flex;
    padding-bottom: 0.8rem;
    border-bottom: 0.2px solid #f1f5f9;
  }

  &__feat {
    padding: 0.4rem 0.8rem;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #034ea2;
    border: 1px solid #034ea2;
    margin-right: 0.8rem;
    border-radius: 0.4rem;
  }

  &__pricing {
    margin-top: 0.8rem;
  }

  &__price {
    &-discounted {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.6rem;

      color: #333333;
    }

    &-per-kg {
      font-weight: 400;
      font-size: 1.2rem;
    }

    &-mrp {
      font-size: 1.6rem;
      line-height: 2.1rem;

      &-line {
        text-decoration: line-through;
        text-decoration-color: red;
        text-decoration-thickness: 3px;
      }
    }

    &-saved {
      color: #2cac4c;
      font-size: 1.6rem;
      line-height: 2.1rem;
      margin-left: 0.4rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__free-delivery {
    display: flex;
    align-items: center;

    p {
      word-wrap: break-word;
      font-size: 1.2rem;
      line-height: 1.6rem;
      width: 12rem;
      margin-left: 1.2rem;
    }
  }

  &__cta {
    font-size: 1.8rem !important;
  }
}
