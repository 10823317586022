.blog {
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 4rem;

  @include devices(mobile) {
    max-width: 90%;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    margin-top: 4.2rem;
  }

  &__breadcrumb {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    max-width: 80vw;
    margin: 0 auto;
    padding-top: 3.2rem;

    color: #2cac4c;
    a {
      text-decoration: none;
      color: #808080;
    }

    @include devices(mobile) {
      display: none;
    }
  }
  &__card {
    width: 32%;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    overflow: hidden;

    // flex-shrink: 0;
    // flex-grow: 1;

    @include devices(mobile) {
      flex-shrink: 1;
      flex-grow: 1;
      width: auto;
    }

    &--image {
      background: #ebebeb;
      text-align: center;
      width: 100%;
      height: 208px;
      position: relative;

      img {
        display: inline-block;
        position: absolute;
        top: 48%;
        width: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 248px;

        @include devices(mobile) {
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }

    &--details {
      padding: 2.4rem;
      p {
        font-size: 15px;
        line-height: 23px;
        max-height: 47px;
        min-height: 47px;
        overflow: hidden;
        padding: 0;
        margin-bottom: 30px;
      }
    }
    &--post-title {
      font-size: 23px;
      overflow: hidden;
      height: 70px;
      font-family: Poppins-bold-600;
      a {
        max-height: 42px;
        display: block;
        text-decoration: none;
        color: #2aac4d;
        cursor: pointer;
        text-decoration: none;
      }
    }
    &--post-date {
      color: #979797;
      font-size: 15px;
      margin: 10px 0;
    }

    h1 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    p {
      font-size: 1.8rem;
      color: #808080;
    }
  }

  &__management {
    margin-top: 12rem;
  }
}

.categories {
  box-shadow: 0 0 8px 3px #efefef;
  margin-bottom: 70px;
  width: 340px;
  margin-right: 45px;
  float: left;

  a {
    color: #f1635c;
    cursor: pointer;
    text-decoration: none;
  }
  p {
    font-size: 15px;
    line-height: 23px;
    max-height: 47px;
    min-height: 47px;
    overflow: hidden;
    padding: 0;
    margin-bottom: 30px;
  }
}
.categories_2 {
  position: relative;
}
.catimg {
  background: #ebebeb;
  text-align: center;
  width: 100%;
  height: 174px;
  position: relative;

  img {
    display: inline-block;
    position: absolute;
    top: 48%;
    width: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 174px;
  }
}

.available_blog {
  position: absolute;
  left: 10%;
  display: inline-block;
  font-size: 12px !important;
  bottom: -21px;
  text-transform: none !important;
}

.blog_button {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  background: #2aac4d;
  text-decoration: none;
  padding: 15px 35px;
  border-radius: 10px;
  text-decoration: none;
}

.full_blog {
  max-width: 50%;
  margin: 0 auto;
  margin-bottom: 4rem;

  @include devices(mobile) {
    max-width: 90%;
  }
  h1 {
    font-size: xx-large;
    font-weight: 800;
  }
}
.blog-content {
  *,
  *::after,
  *::before {
    margin: revert !important;
    padding: revert !important;
  }
  color: #757575;
  font: 16px Roboto, sans-serif;
  line-height: 1.6em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-number {
  padding: 8px 15px;
  margin: 0 4px;
  cursor: pointer;
}



.page-number.active {
  background-color: #2aac4d;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
}
