.youtube__container {
  @include devices(mobile) {
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 0;
  }
  margin-top: 2rem;
  width: 100%;
  display: flex;
  column-gap: 2rem;
  row-gap: 0;
  height: max-content;
  &-slide {
    @include devices(mobile) {
      width: 100%;
    }
    width: 95%;
    height: 100%;
    background: #ffffff;
    // box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;
  }
  &-article {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    p {
    overflow: hidden;
    max-height: 44px;
    text-align: center;
    width: 100%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
      @include devices(mobile) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    a {
      text-decoration: none;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #2cac4c;
      width: 100%;
      text-align: center;
      padding-bottom: 2.5rem;
    }
  }
  &__button {
    width: 5.2rem;
    height: 5.2rem;
    background-color: $color-white;
    border-radius: 50%;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    @include devices(mobile) {
      display: none;
    }

    &--right {
      img {
        // position: relative;
        // top: 3%;
        // right: -4%;
        padding: 30%;
        margin: 0 auto;
      }

      margin-left: 1rem;
    }

    &--left {
      img {
        // position: relative;
        // top: 3%;
        // right: 5%;
        padding: 30%;
      }

      margin-right: 1rem;
    }

    &--mobile {
      width: 5.2rem;
      height: 5.2rem;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      margin-right: 2.4rem;

      img {
        position: relative;
        margin: 0 auto;
      }
    }
  }
}
.carousel__aboutus {

  @include devices(mobile) {
    margin-bottom: 2rem;
  }

  & .slider {
    min-width: 33.33%;
    max-width: 33.33%;
    margin-right: 4.8rem;

    @include devices(mobile) {
      min-width: 100%;
    }
  }

  & .carousel-slider {
    display: flex;
    align-items: center;
  }
}

.leadership {
  max-width: 75%;
  margin: 0 auto;
  margin-bottom: 8rem;
  margin-top: 8rem;

  @include devices(mobile) {
    max-width: 90%;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    margin-top: 4.2rem;
  }

  &__card {
    width: 32%;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    overflow: hidden;

    // flex-shrink: 0;
    // flex-grow: 1;

    @include devices(mobile) {
      flex-shrink: 1;
      flex-grow: 1;
      width: auto;
    }

    &--image {
      width: 100%;
      height: 23.4rem;
      // background-color: #f1f5f9;

      img {
        height: 100%;
        width: 100%;
        object-fit:cover;
       
        

        @include devices(mobile) {
          object-fit: cover;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }

    &--details {
      padding: 2.4rem;

      img {
        margin-top: 1.2rem;
      }
    }

    h1 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    p {
      font-size: 1.8rem;
      color: #808080;
    }
  }

  &__management {
    margin-top: 12rem;
  }
}

.join {
  padding-top: 6rem;
  width: 75vw;
  margin: 0 auto;
  display: flex;
  margin-bottom: 10rem;
  flex-wrap: wrap;

  @include devices(mobile) {
    width: 90vw;
  }

  &__desc {
    font-size: 1.8rem;
    line-height: 150%;
    margin-top: 1.6rem;
  }

  &__mail {
    margin-top: 2.4rem;
    margin-bottom: 3.6rem;

  }
  &__left {
    width: 45%;

    @include devices(mobile) {
      width: 100%;
      text-align: center;
    }
  }
  &__media{
      width: 55%;
      img{
      width: 88%;
      height: auto;
      margin-left: 8rem;
      @include devices(mobile) {
        margin-left: 0;
        margin-top: 2rem;
      }
      }
      @include devices(mobile) {
        width: 100%;
        text-align: center;
      }
  }
}

.news {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(198, 233, 207, 0.08) 24.48%,
    rgba(125, 204, 145, 0.08) 61.46%,
    rgba(44, 172, 76, 0.08) 100%
  );
  &__container {
    max-width: 75vw;
    margin: 0 auto;
    padding-bottom: 6rem;

    @include devices(mobile) {
      padding-bottom: 0;
    }
  }

  &__carousel {
    margin-top: 4.2rem;
    
  }

  &__article {
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    img {
      width: 32rem;
      height: 21rem;
    }

    p {
      margin: 1.6rem;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      /* or 122% */

      text-align: center;

      /* Primary/Black */

      color: #333333;
    }

    a {
      margin-bottom: 1.6rem;
      text-decoration: none;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      /* Primary/Green1 */

      color: #2cac4c;
    }
  }
}

.investors{
  padding-bottom:4rem;
  padding-top: 4rem;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(198, 233, 207, 0.08) 24.48%,
    rgba(125, 204, 145, 0.08) 61.46%,
    rgba(44, 172, 76, 0.08) 100%
  );

  &__container {
    max-width: 75vw;
    margin: 0 auto;
  }

  p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    margin-top: 1.6rem;
  }
  img{
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include devices(mobile) {
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  &__mail{
    width: 30%;
    padding: 0.8rem 1.2rem;
    background-color: rgba(3, 78, 162, 0.12);
    font-style: italic;
    font-size: 1.8rem;
    border-radius: 0.4rem;
    margin-bottom: 1.2rem;
    margin-top: 2.4rem;
    @include devices(mobile) {
      width: 100%;
      text-align: center;
    }
  }
}
