.cattleC {
  width: 40%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;

  @include devices(mobile) {
    width: 90vw;
    margin-right: 0;
  }

  &__thumbnail {
    img {
      border-top-left-radius: 1.4rem;
      border-top-right-radius: 1.4rem;
      height: 25rem;
      width: 100%;
      display: block;
    }

    position: relative;
    &--lables {
      font-size: 2rem;
      line-height: 1.4rem;
      font-weight: 500;
      position: absolute;
      bottom: 10%;

      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 3.2rem;
    }

    &--lable {
      display: flex;
      align-items: center;
      background: rgba(3, 78, 162, 0.6);
      padding: 1rem;

      &:nth-child(1) {
        border-radius: 0px 17px 17px 0px;
      }

      &:nth-child(2) {
        border-radius: 17px 0px 0px 17px;
      }

      img {
        width: 2rem;
        margin-right: 1rem;
      }
    }
  }

  &__check {
    text-align: center;
    font-size: 1.8rem;
    line-height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(44, 172, 76, 0.2);

    padding: 0.4rem;

    p {
      margin-left: 1rem;
    }
  }

  &__heading {
    font-size: 2.4rem;
    line-height: 2.4rem;

    font-weight: 700;

    margin: 1.6rem auto 0.8rem 1.6rem;
  }

  &__sub {
    display: flex;
    font-size: 1.8rem;
    margin: 0 0.6rem;
    margin-bottom: 1.8rem;

    &--item {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-right: 2rem;

      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }

      &--line {
        border-right: 0.8px solid #808080;
      }
    }
  }

  &__feats {
    display: flex;
    justify-content: space-between;
    margin: 0 1.6rem;
  }

  &__feat {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
    }

    &--lable {
      font-size: 1.8rem;
      color: #333333;

      margin: 1.4rem auto 0.8rem auto;
    }

    &--number {
      color: #2cac4c;
      font-size: 2rem;

      font-weight: 500;
      line-height: 2rem;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    padding: 1.3rem;
    border: 0.99375px solid #f1f5f9;
    margin-top: 1.2rem;

    &--lable {
      display: flex;
      align-items: flex-end;
      line-height: 16px;

      img {
        margin-right: 0.8rem;
      }
    }

    &--amount {
      display: flex;
      align-items: flex-end;
    }

    &--original {
      text-decoration: line-through;
      text-decoration-color: red;
      text-decoration-thickness: 1.5px;
      margin-right: 4px;
    }

    &--discounted {
      color: #2cac4c;
      font-size: 2rem;
    }
  }

  &__location {
    display: flex;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 1.5rem;

    img {
      margin-right: 1rem;
    }
  }

  &__ctas {
    padding: 1.6rem;
    padding-top: 0;

    display: flex;
    justify-content: space-between;

    gap: 1.6rem;
    font-size: 1.4rem;
  }
}
