.career {
  width: 100vw;
  &__heroContainer {
    height: 50vh;
    width: 100%;
    background-image: url('../../assets/careerHero.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 10vw 10vh 10vw;

    &-content {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      row-gap: 2rem;
      h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 133.3%;
        color: #ffffff;
      }
      h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: justify;
        color: #ffffff;
        width: 70%;
      }
      a {
        cursor: pointer;
        text-decoration: none;
        background: #2cac4c;
        border: 0.4px solid #2cac4c;
        border-radius: 4px;
        width: fit-content;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;
        text-align: center;
        padding: 0.75rem 1.75rem;
      }
    }
  }
}

.careerCard {
  position: relative;
  padding: 0vh 10vw 0vh 10vw;
  margin-top: 10vh;
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 133.3%;
      color: #333333;
      width: fit-content;
      padding: 0 3.5rem 0 3.5rem;
      background-color: white;
    }
    span {
      position: absolute;
      top: 20px;
      left: 10vw;
      width: 80%;
      height: 0;
      z-index: -1;
      border: 1px solid #e6e6e6;
    }
  }
}
.careerCard__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5rem;
  &-card {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    row-gap: 3rem;
    min-height: 25vh;
    img {
      width: 160px;
      height: 160px;
      background: #f1f5f9;
      border-radius: 100%;
      object-fit: scale-down;
      // object-fit: contain;
      object-position: center;
    }
    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #333333;
    }
  }
}
.careerPerks {
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(44, 172, 76, 0.08) 100%,
    rgba(125, 204, 145, 0.08) 61.46%,
    rgba(198, 233, 207, 0.08) 24.48%,
    rgba(255, 255, 255, 0) 0%
  );
  // transform: matrix(1, 0, 0, -1, 0, 0);
  padding: 0vh 10vw 0vh 10vw;
  margin-top: 10vh;
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 133.3%;
      color: #333333;
      width: fit-content;
      padding: 0 3.5rem 0 3.5rem;
      background-color: white;
    }
    span {
      position: absolute;
      top: 20px;
      left: 10vw;
      width: 80%;
      height: 0;
      z-index: -1;
      border: 1px solid #9ac846;
    }
  }
}
.careerPerks__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5rem;
  &-card {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    row-gap: 3rem;
    min-height: 25vh;
    img {
      width: 160px;
      height: 160px;
      background: #f1f5f9;
      border-radius: 100%;
      object-fit: scale-down;
      // object-fit: contain;
      object-position: center;
    }
    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #333333;
    }
  }
}
