.footer {
  &__container {
    max-width: 75%;
    margin: 0 auto;
    margin-top: 6rem;
    border-bottom: 2px solid #e6e6e6;

    display: grid;
    grid-template-columns: 25% 73%;
    gap: 2%;

    @include devices(mobile) {
      max-width: 90%;
      display: flex;
      flex-direction: column;
    }
  }

  &__details {
    &--logo {
      width: 10rem;
    }

    &--tag-line {
      margin-top: 1.2rem;
      font-size: 2.4rem;
      line-height: 3.1rem;
      margin-bottom: 1.6rem;
    }

    &--socials {
      margin-top: 2.4rem;
    }
    &--social-icon {
      margin-right: 2.1rem;
    }

    margin-bottom: 2rem;
  }

  &__links {
    display: grid;
    grid-template-columns: 20% 20% 25% 35%;
    @include devices(mobile) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    margin-left: 2rem;

    @include devices(mobile) {
      margin-left: 0;
      margin-bottom: 3.6rem;
    }

    &--header {
      font-weight: 600;
    }

    &--item {
      color: #333;
      font-size: 1.6rem;
      font-weight: 400;
      text-decoration: none;
      margin-top: 1.6rem;

      &--horizontal {
        color: #333;
        margin-right: 0.5rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1.6rem;
        font-weight: 400;
      }
    }
    &--h-container {
      display: flex;
      flex-wrap: wrap;
      width: 70%;

      @include devices(mobile) {
        width: 90%;
      }
    }
  }

  &__copyright {
    padding-top: 3rem;
    font-size: 1.6rem;
    text-align: center;
    img {
      width: 100%;
      
    }
  }

  &__tnc-links {
    display: flex;
    justify-content: center;
    color: #333333;

    a {
      // text-decoration: none;
      padding: 0 1rem;

      &:visited {
        color: #333333;
      }
    }
  }
}
