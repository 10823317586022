.button {
  border: none;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.1rem;
  color: #ffffff;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
 

  @include devices(mobile) {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    width: -webkit-fill-available;
  }

  &__icon {
    margin-right: 1rem;

    &--right {
      margin-left: 1.2rem;
    }
  }

  &__primary {
    background-color: #2cac4c;
    display: flex;
    padding: 1.6rem 2.4rem;
    align-items: center;
    border-radius: 0.4rem;
    justify-content: center;
    transition: background-color 0.3s ease;

    &--play {
      padding: 1.2rem 1.6rem;
    }

    @include devices(mobile) {
      justify-content: center;
    }
    &:hover {
      background-color:  #008120; 
    }
  }

  &__secondary {
    display: flex;
    align-items: center;
    margin-left: 1.6rem;
    color: #2cac4c;
    background-color: transparent;

    &--icon {
      margin-left: 0.8rem;
    }

    @include devices(mobile) {
      justify-content: center;
      margin-top: 2.7rem;
    }
  }

  &__outline {
    display: flex;
    align-items: center;
    // margin-left: 1.6rem;
    background-color: transparent;
    border-radius: 4px;
    padding: 1.6rem;
    // margin-top: 1.6rem;

    @include devices(mobile) {
      justify-content: center;
      padding: 1.4rem;
    }
  }


}

.customWidth
{
  width: -webkit-fill-available;
}