.hero {
  background: url(../../assets/grass.svg),
    linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(198, 233, 207, 0.08) 24.48%,
      rgba(125, 204, 145, 0.08) 61.46%,
      rgba(44, 172, 76, 0.08) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 100%;
  height: 81vh;
  z-index: 33;

  @include devices(mobile) {
    height: 51rem;
  }

  &_invisible-box {
    position: absolute;
    top: 47.5rem;
    right: 0;
    left: 47%;
    display: block;
    width: 40%;
    height: 40%;
    text-decoration: none;
    z-index: 1;
    @include devices(mobile) {
      top: 43rem;
      left: 52%;
      transform: translateX(-50%);
      width: 70%;
      height: 29%;
    }
  }

  &__container {
    max-width: 80%;
    margin: 0 auto;
    padding-top: 7.5rem;
    padding-bottom: 22rem;
    background: url(../../assets/HomeImg.webp);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: right bottom;
    height: 100%;

    @include devices(mobile) {
      padding-top: 0;
      padding-bottom: 0;
      background-size: 95%;
      max-width: 80vw;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 6.3rem;
    margin-top: 1.6rem;
    color: #333333;
    width: 40%;

    @include devices(mobile) {
      text-align: center;
      font-size: 2.4rem;
      line-height: 133%;
      margin-top: 0.8rem;
      width: 100%;
    }
  }

  &__quote {
    display: flex;
    border-left: 2px solid #2cac4c;
    padding: 1.2rem;
    margin-top: 1.2rem;
    align-items: center;

    &--text {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-left: 0.8rem;

      @include devices(mobile) {
        font-weight: 400;
        font-size: 1.6rem;
      }
    }

    @include devices(mobile) {
      border-left: none;
      padding-top: 0;
      justify-content: center;
    }
  }

  &__button {
    margin-top: 1.6rem;

    @include devices(mobile) {
      margin-top: 0;
      text-align: center;
        }
  }
}

.weoffer {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(198, 233, 207, 0.08) 24.48%,
    rgba(125, 204, 145, 0.08) 61.46%,
    rgba(44, 172, 76, 0.08) 100%
  );
  &__container {
    max-width: 78vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 33% 67%;
    padding: 12.5rem 0;

    @include devices(mobile) {
      display: block;
      padding: 3.6rem 0;
      max-width: 85%;
    }
  }

  &__cards-mobile {
    display: none;

    @include devices(mobile) {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch; /* for smoother scrolling on iOS */
      scroll-behavior: smooth;
    }
  }

  &__stats {
    @include devices(mobile) {
      justify-content: space-between;
    }
  }

  &__stat {
    display: flex;
    align-items: center;
    margin-top: 3rem;

    @include devices(mobile) {
      &:first-child:after {
        content: '';
        background-color: #ffffff;
        position: relative;
        width: 2px;
        height: 3.6rem;
        top: 4px;
        left: 21%;
        display: block;
      }
    }

    &--text {
      display: flex;
      flex-direction: column;
      margin-left: 2.4rem;
    }

    &--no {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #333333;
    }

    &--what {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      margin-top: 0.5rem;

      color: #808080;
    }
  }

  &__button {
    margin-top: 4rem;
    padding: 1.2rem 1.6rem;

    @include devices(mobile) {
      margin-top: 2.4rem;
      text-align: center;
      white-space: pre-line;
    }
  }

  &__col2 {
    display: grid;
    grid-template-columns: 50% 50%;

    &--1 {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>*:first-child {
        margin-bottom: 4.2rem;
      }
    }

    &--2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>*:first-child {
        margin-top: 6.3rem;
        margin-bottom: 4.2rem;
      }
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__card {
    border: 1px solid #e6e6e6;
    border-radius: 0.8rem;
    width: 90%;

    @include devices(mobile) {
      margin-top: 2.4rem;
      width: 100%;
      flex-shrink: 0;
      margin-right: 3rem;
      scroll-snap-align: start;
    }

    &--title {
      padding: 1.2rem 3.8rem;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      font-weight: 500;
      color: #333333;

      @include devices(mobile) {
        font-size: 1.8rem;
        line-height: 2.1rem;
      }
    }

    &--description {
      display: flex;
      padding: 2.4rem 2.4rem 2.4rem 0;

      @include devices(mobile) {
        padding: 1.2rem 1.2rem 1.2rem 0;
      }
    }

    &--icon {
      padding: 0 2.4rem;

      @include devices(mobile) {
        padding: 0 1.2rem;
      }
    }

    &--text {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }
}
.weoffer__dot-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.weoffer__dot {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d8d8d8; 
  margin: 0 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.weoffer__dot.active {
  background-color: #2cac4c; 
}

.weoffer__dot.active .weoffer__dot-circle {
  display: block;
}

.weoffer__dot-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #2cac4c; 
  display: none;
}


.syc {
  background-image: url(../../assets/sycbg.png);
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__overlay {
    background: rgba(0, 0, 0, 0.84);
  }

  &__container {
    max-width: 78vw;
    margin: 0 auto;

    @include devices(mobile) {
      display: none;
          }

    &--mobile {
      // max-width: 78vw;
      // margin: 0 auto;
      display: none;

      @include devices(mobile) {
        display: flex;
      }
    }
  }

  &__content {
    // display: none;
    justify-content: space-between;
    // transition: opacity 1s ease-out;
    // opacity: 0;

    transition: opacity 2s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;

    &--selected {
      display: flex;

      opacity: 1;
      height: auto;
    }

    &--mobile {
      flex: 1 0 100%;
      gap: 2rem;
    }
  }

  &__phone {
    margin-top: 4rem;
    height: 662px;
    object-fit: cover;
    object-position: center;

    @include devices(mobile) {
      display: none;
    }
  }

  &__info {
    margin-top: 13.5rem;

    @include devices(mobile) {
      margin: 2rem 0.6rem;
      display: flex;
      gap: 2rem 0;
      flex-direction: column;
    }

    &--subheading {
      color: #e5e5e5;

      &::before {
        border-top: 2px solid #e5e5e5;
      }

      @include devices(mobile) {
        font-size: 1.5rem;
        color: #2cac4c;
      }
    }

    &--heading {
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 133%;

      color: #e5e5e5;

      @include devices(mobile) {
        font-size: 2.2rem;
      }
    }

    &--steps {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-top: 2.4rem;
      align-items: flex-start;

      @include devices(mobile) {
        // margin: 0 20vw;
        gap: 1.7rem;
        padding: 0 10vw;
        flex-wrap: wrap;
       
      }
    }

    &--step {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      width:auto;
      height: auto;
      margin-right: 9rem;
      @include devices(mobile) {
        gap: 0.2rem;
        width: 30vw;
        height: initial;
        margin-right: 0rem;
      }

      &--icon {
        @include devices(mobile) {
          height: 6rem;
          width: 6rem;
          object-fit: contain;
        }
      }

      &--action {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-align: center;
        color: #2cac4c;

        @include devices(mobile) {
          // font-size: 1.2rem;
        }
      }

      &--desc {
        font-size: 1.6rem;
        line-height: 2rem;
        color: #808080;
        text-align: center;
        

        @include devices(mobile) {
          font-size: 1.5rem;
          line-height: 1.2em;
        }
      }

      &-feed {
        color: white;
        width: 100%;
        text-align: center;

        @include devices(mobile) {
          width: auto;
        }

        &--icon {
          // width: 100%;

          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
          }

          @include devices(mobile) {
            width: 6rem !important;
            height: 6rem !important;
            position: relative;
            border-radius: 50%;
            outline: 2px solid white;
            object-fit: contain;
            border-radius: 100%;
            overflow: hidden;
          }
        }

        &--action {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.1rem;
          margin-top: 1rem;
          color: white;
        }

        &--desc {
          font-size: 1.6rem;
          line-height: 2rem;
          color: white;
          width: 96%;
          margin-top: 1rem;

          @include devices(mobile) {
            display: none;
            margin-top: 0em;
            font-size: 1rem;
            line-height: 1.2em;
            flex-wrap: wrap;
          }
        }
      }
    }

    &--button {
      margin-top: 3.6rem;

      @include devices(mobile) {
        margin: 1rem;
        display: flex;
        flex-direction: column;
       
        button{
          width: -webkit-fill-available;
        }
      }

      &__btn {
        @include devices(mobile) {
          width: 100%;
      }
    }
  }
}

  &__buttons {
    display: flex;
    justify-content: space-around;

    border: 1px solid #e6e6e6;
    border-top-left-radius: 6.6rem;
    border-top-right-radius: 6.6rem;

    @include devices(mobile) {
      display: none;
    }
  }

  &__button {
    padding: 1rem 0;
    cursor: pointer;
    background-color: transparent;

    &:not(:last-child) {
      border-right: 1px solid #e6e6e6;
    }

    font-size: 1.8rem;
    color: #e6e6e6;
    width: 100%;
    text-align: center;
    font-weight: 600;

    &:first-child {
      border-top-left-radius: 6.6rem;
    }

    &:last-child {
      border-top-right-radius: 6.6rem;
    }

    &--selected {
      background-color: #f8c346;
      border: none;
      color: #333333;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
}

.thumbs-wrapper {
  display: none;
}

.support {
  @include devices(mobile) {
    // background-image: url(../../assets/support_bg.png);
    // background-size: auto;
    // background-repeat: no-repeat;
  }

  &__container {
    max-width: 75%;
    margin: 11.5rem auto;
    background-image: url(../../assets/support_bg.png);
    background-size: auto;

    display: flex;
    align-items: center;

    @include devices(mobile) {
      max-width: 90%;
      margin: 0 auto;
      flex-direction: column;
      background-image: none;
    }
  }

  &__image {
    width: 43%;

    @include devices(mobile) {
      // margin-top: 2rem;
      // height: 400px;
      // width: 350px;

      // border-radius: 8px;
    }
  }

  &__content {
    width: 57%;
    margin-top: 6rem;

    @include devices(mobile) {
      width: 100%;
      margin-top: 1.6rem;
    }
  }

  &__subheading {
    color: #2cac4c;
  }

  &__usps {
    margin-top: 1.6rem;
  }

  &__usp {
    display: flex;
    align-items: center;

    @include devices(mobile) {
      // justify-content: center;
      margin-left: 15%;
      margin-bottom: 1.6rem;
    }

    &--icon {
      width: 5.7rem;
      height: 5.7rem;
      background-image: url('../../assets/tick.svg');
      border-radius: 50%;
      margin-right: 1.6rem;

      @include devices(mobile) {
        width: 3rem;
        height: 3rem;
        background-size: contain;
      }
    }

    &--text {
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;

      @include devices(mobile) {
        font-size: 1.8rem;
      }
    }
  }

  &__cta {
    padding-top: 3.6rem;
    padding-bottom: 6rem;
    display: flex;
    align-items: center;

    @include devices(mobile) {
      padding-top: 2rem;
      padding-bottom: 3.6rem;
      flex-direction: column;
      align-items: normal;
    }

    &--1 {
      margin-right: 1.6rem;

      @include devices(mobile) {
        margin-bottom: 1.6rem;
        width: 100%;
      }
    }

    &--2 {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.1rem;
      padding: 1.8rem;
      border: 2px solid #2cac4c;
      color: #2cac4c;
      border-radius: 0.4rem;

      @include devices(mobile) {
        width: 100%;
      }
    }
  }
}

.carousel .slide {
  // min-width: 30.5% !important;
  // max-width: 30.5% !important;
  // margin-right: 4.8rem !important;

  @include devices(mobile) {
    // min-width: 97% !important;
  }

  iframe {
    width: 100%;
    height: 100%;
    // width: calc(100% - 100px);
    border-radius: 1.4rem;
    height: 26rem;
    margin: 0;

    @include devices(mobile) {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

.carousel-root {
  width: 100%;
}

.faq {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 14.4rem;
  margin-top: 14rem;

  @include devices(mobile) {
    width: 90vw;
    margin-top: 3.6rem;
    margin-bottom: 3.6rem;
  }


  &__container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 6rem;

    @include devices(mobile) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.6rem;
    }
  }

  &__question {
    font-size: 1.8rem;
    line-height: 2.1rem;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    padding: 2.4rem 1.6rem;
    border-bottom: 2px solid #f1f5f9;
    cursor: pointer;

    @include devices(mobile) {
      gap: 1.2rem;
      grid-template-columns: 10% 70% 20%;
    }

    &--circle {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      background-color: #2cac4c;
    }

    &--right-arrow {
      width: 1.7rem;
      height: 1.7rem;
      transform: rotate(45deg);
      border: 3px solid #2cac4c;
      border-left: 0;
      border-bottom: 0;
      @include devices(mobile) {
        display: none;
      }
    }
   
    &--active {
      border: 1px solid #2cac4c;
    }
  }

  &__answer {
     display: none;
     
    @include devices(mobile) {
      display: block; // Display the answer container for mobile view
    }

    padding: 3.6rem 3rem;
    background: #f1f5f9;

    &--hidden {
      display: none;
    }

    &--heading {
      font-size: 2.8rem;
      line-height: 3.3rem;

      font-weight: 600;

      color: #333333;
    }

    &--content {
      padding-top: 2.4rem;
      font-size: 1.8rem;
      line-height: 150%;

      &-item {
        padding: 1rem;
      }
    }
  }

  &_ansDesktop{
    display: block;

    @include devices(mobile) {
      display: none;
    }
    padding: 3.6rem 3rem;
    background: #f1f5f9;

    &--hidden {
      display: none;
    }

    &--heading {
      font-size: 2.8rem;
      line-height: 3.3rem;
      font-weight: 600;
      color: #333333;
    }

    &--content {
      padding-top: 2.4rem;
      font-size: 1.8rem;
      line-height: 150%;

      &-item {
        padding: 1rem;
      }
    }
  }
  &--down-arrow {
      
    @include devices(mobile) {
      width: 1.7rem;
      height: 1.7rem;
      transform: rotate(133deg);
      border: 3px solid #2cac4c;
      border-left: 0;
      border-bottom: 0;
      display: block;
      
    }
  }
}

.about_us {
  background: url('../../assets/aboutus_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  &__container {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 75%;
    margin: 0 auto;

    @include devices(mobile) {
      max-width: 86vw;
      grid-template-columns: none;
      text-align: center;
    }
  }

  &__content {
    margin-top: 10.5rem;
    margin-bottom: 23.6rem;

    @include devices(mobile) {
      margin-top: 3.6rem;
      margin-bottom: 0rem;

      button {
        width: 80%;
        margin-top: 1.6rem;
      }
    }
  }

  &__para {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 150%;
    margin-bottom: 1.6rem;
  }

  &__media {
    width: 35vw;
    height: 37.5rem;

    iframe {
      border-radius: 1.4rem;
      border: transparent;
      margin: 6rem;
    }

    @include devices(mobile) {
      width: 95%;
      height: 21rem;

      iframe {
        margin: 1rem;
      }

      margin-bottom: 13.6rem;
    }
  }

  &__stats {
    display: flex;
    margin: 0 auto;
    max-width: 75%;

    position: relative;
    top: -9rem;

    @include devices(mobile) {
      // flex-direction: row;
      // flex-wrap: wrap;
      // max-width: 90vw;
      // justify-content: center;
      max-width: 90%;
      display: grid;
      grid-template-columns: 48% 48%;
      column-gap: 1.6rem;
      row-gap: 1.6rem;
    }
  }

  &__card {
    background-color: #ffffff;
    min-width: 19%;
    margin-right: 2.4rem;
    padding: 2.4rem;
    border-radius: 1.4rem;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);

    @include devices(mobile) {
      width: 100%;
      padding: 1.6rem;
      // margin-bottom: 1.6rem;

      // margin-right: 1.6rem;
    }

    &--text {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #808080;

      @include devices(mobile) {
        font-size: 1.8rem;
        line-height: 2.1rem;
      }
    }

    &--number {
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin-top: 0.8rem;

      @include devices(mobile) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
  }
}

.download {
  display: flex;
  justify-content: space-between;
  max-width: 75%;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 12rem;

  @include devices(mobile) {
    margin-bottom: 4rem;
    max-width: 80%;
    justify-content: center;
  }

  &__subheading {
    @include devices(mobile) {
      color: #2cac4c;
    }
  }

  &__heading {
    @include devices(mobile) {
      font-weight: 500;
    }
  }

  &__media {
    width: 50%;

    img {
      width: 100%;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__feats {
    margin-top: 2.4rem;
    list-style: none;
   

    @include devices(mobile) {
      display: flex;
      flex-direction: column;
      gap: 0rem;
      // width: 85%;
      margin: 0 auto;
      // margin-top: 2.4rem;
    }

    &--feat {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-bottom: 1.2rem;
      

      &--text {
        margin-left: 1.2rem;
        font-weight: 300;
        font-size: 2.4rem;

        @include devices(mobile) {
          text-align: left;
          font-size: 1.8rem;
          flex: 1;
          margin-left: 0;
        }
      }

      &--icon {
        width: 3.6rem !important;
        height: 3.6rem;
      }
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    margin-top: 3.6rem;

    @include devices(mobile) {
      flex-direction: column;
      margin-top: 2.8rem;
    }
  }

  &__cta {
    margin-right: 1.6rem;

    @include devices(mobile) {
      margin-bottom: 1.6rem;
      width: 100%;
      margin-right: 0;

      button {
        width: 100%;
      }
    }
  }
}

.testimonial {
  max-width: 75vw;
  margin: 0 auto;
  margin-top: 10rem;
  margin-bottom: 10rem;

  @include devices(mobile) {
    margin-top: 3.6rem;
    max-width: 90%;
    // display: flex;
    // flex-direction: column;
  }

  &__header {
    margin-top: 7rem;
    position: absolute;

    @include devices(mobile) {
      margin-top: 3.6rem;
      position: relative;
    }
  }

  &__container {
    background-image: url('../../assets/testimonial_bg.svg');
    background-size: 100% 100%;
    background-position-x: 1rem;
    background-repeat: no-repeat;
    display: flex;
    padding-left: 15rem;

    @include devices(mobile) {
      padding: 0;
      flex-direction: column-reverse;
    }
  }

  &__card {
    box-shadow: 0px 0px 42px rgba(51, 51, 51, 0.2);
    width: 54rem;
    border-radius: 8px;
    transform: translateX(-5rem);
    margin-top: 30rem;
    z-index: -44;

    @include devices(mobile) {
      margin-top: 0;
      transform: translateX(0);
      width: 100%;
      z-index: 50;
      // position: relative;
      // top: -6rem;
    }

    &--title {
      background: rgba(44, 172, 76, 1);
      border-radius: 8px 0px 0px 0px;
      font-style: italic;
      font-size: 2.4rem;
      font-weight: 500;
      padding: 1.6rem 0;
      // padding-left: 3.6rem;
      color: #ffffff;
      text-align: center;

      @include devices(mobile) {
        background: rgba(44, 172, 76, 1);
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding: 1rem;
      }
    }

    &--text {
      padding: 1.6rem 3.6rem;
      font-size: 2.4rem;

      @include devices(mobile) {
        text-align: center;
      }
    }

    &--stars {
      display: flex;
      justify-content: center;
      margin-top: 0.6rem;
    }

    &--star {
      margin-right: 2.1rem;

      @include devices(mobile) {
        width: 3.3rem;
      }
    }

    &--quote {
      margin-top: 3.2rem;
      line-height: 3.2rem;

      @include devices(mobile) {
        margin-top: 2.2rem;
        font-size: 1.8rem;
        text-align: center;
      }
    }

    &--sign-name {
      margin-top: 2.7rem;
      font-weight: 600;

      @include devices(mobile) {
        margin-top: 1.6rem;
      }
    }

    &--sign-location {
      margin-top: 0.4rem;
      font-size: 1.8rem;
      font-style: italic;
      color: $color-primary-grey;

      @include devices(mobile) {
        margin-top: 0;
      }
    }
  }

  &__media {
    width: 60rem;
    height: 40rem;
    background-color: skyblue;
    border-radius: 8px;
    transform: translate(-6rem, 7.2rem);

    iframe {
      border: 0;
      border-radius: 8px;
      position: relative;
      z-index: 555;

      @include devices(mobile) {
        border-radius: 0;
      }
    }

    @include devices(mobile) {
      transform: translate(0, 0);
      width: 99.708%;
      height: 21rem;
      margin-top: 2.4rem;
      position: relative;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.carouselbtn1{
   position: absolute;
    bottom: 1.8rem;
    right: 1rem;
    left: 1rem;
    padding: 1rem;
    width: 40rem;

}
.carouselbtn2{
  position: absolute;
    bottom: 1rem;
    padding: 1rem;
    // width:38rem;
    right: -0.1rem;
    left:0rem;

}

.weOfferbtn{
  width: 84%;
  @include devices(mobile) {
    width: 100%;
  }
}


.policy-section {
  line-height: 1.6;
}
.policy-section h2 {
  font-size: 3.2rem;
}

.policy-section li {
  font-size: 1.7rem;
}

.policy-section ul,
.policy-section ol {
  padding-left: 20px;
}

.policy-section li {
  margin-bottom: 8px;
}

.policy-section h2 {
  margin-top: 20px;
}

.policy-section ol ol {
  list-style-type: lower-alpha;
  margin-top: 8px;
}

.policy-section li strong {
  color: #007bff; 
}

.clicked-text {
  color: inherit;
  text-decoration: none;
}

.clicked-text:hover {
  text-decoration: underline;
  color: #551A8B;
}
.sub-text{
  color: inherit;
  text-decoration: none;
  font-weight: 800;
}
.sub-text:hover{
  text-decoration: underline;
  color: #551A8B;
  font-weight: 600;
}