.cattleDetail {
  max-width: 80vw;
  margin: 0 auto;

  @include devices(mobile) {
    max-width: 90vw;
  }
  &__breadcrumb {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    max-width: 80vw;
    margin: 0 auto;
    padding-top: 3.2rem;

    color: #2cac4c;
    a {
      text-decoration: none;
      color: #808080;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__container {
    display: flex;
    margin-top: 3.2rem;
    margin-bottom: 12rem;
    @include devices(mobile) {
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__images {
    width: 35%;
    &-active {
      border: 0.8px solid #034ea2 !important;
      color: #034ea2 !important;
    }
    @include devices(mobile) {
      width: 100%;
    }
    &-buttons {
      display: flex;
      column-gap: 3rem;
      button {
        width: 155px;
        height: 31px;
        left: 313px;
        top: 214px;
        background: transparent;
        border: 0.8px solid #e6e6e6;
        border-radius: 4px;
        margin-bottom: 3rem;
        color: #808080;
        cursor: pointer;
      }
    }
  }
  &__image {
    width: 100%;
    img {
      object-fit: contain;
      object-position: center;
      max-width: 100%;
      border-radius: 8px;
    }
  }

  // details part
  &__details {
    width: 65%;
    padding-left: 5rem;
    @include devices(mobile) {
      width: 100%;
      margin-top: 2rem;
    }
  }
  &__heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 133.33%;
    color: #333333;
  }
  &__agebyat {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #333333;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  &__milkguarantee {
    font-family: 'Noto Sans Devanagari';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 5rem;
  }

  &__milkDetailsContainer {
    display: flex;
    width: 25vw;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    column-gap: 2rem;
  }

  &__milkDetail {
    background: #ffffff;
    /* Primary/Grey2 */
    min-height: 8vh;
    border: 0.6px solid #e6e6e6;
    border-radius: 4px;
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      font-family: 'Mukta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #808080;
    }
    h1 {
      font-family: 'Mukta';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: #333333;
    }
    &-header {
      font-family: 'Mukta';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #034ea2;
    }
    &-body {
      margin-top: 3rem;
      display: flex;
      column-gap: 5rem;
    }
  }

  &__benefits {
    display: flex;
    margin-top: 5rem;
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 6rem;
    text-align: center;
    @include devices(mobile) {
      margin-right: 0;
    }

    img {
      font-size: 18px;
      line-height: 22px;
      width: 60px;
      height: 60px;
      position: relative;
      background: #fafafa;
      border-radius: 100%;

      @include devices(mobile) {
        width: 3rem;
        height: 2.5rem;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 1.2rem;

      @include devices(mobile) {
        font-size: 1.4rem;
      }
    }
  }
  &__address {
    background: rgba(248, 195, 70, 0.1);
    border: 0.6px solid #f8c346;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: max-content;
    margin-top: 2rem;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #333333;
  }

  &__cattleCondition {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 3rem;
    &--card {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      row-gap: 1rem;
      width: 45%;
      height: 40vh;
      h1 {
        font-family: 'Noto Sans Devanagari';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        color: #333333;
        &::before {
          display: inline-block;
          content: '';
          border-top: 2px solid #333;
          width: 3.6rem;
          margin-right: 0.8rem;
          transform: translateY(-0.5rem);
        }
      }
      p {
        font-family: 'Mukta';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        /* Primary/Grey Text */

        color: #808080;
      }
      img {
        object-fit: contain;
        border-radius: 4px;
      }
    }
  }
}

.carousel {
  &__image {
    object-fit: contain;
  }
  video {
    height: 40vh;
    object-fit: contain;
  }
}
