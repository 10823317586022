.sycf {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(198, 233, 207, 0.08) 24.48%,
    rgba(125, 204, 145, 0.08) 61.46%,
    rgba(44, 172, 76, 0.08) 100%
  );

  padding-bottom: 6rem;
  &__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 133.3%;
    text-align: center;
    margin-top: 7.5rem;

    display: none;

    @include devices(mobile) {
      display: block;
      max-width: 90vw;
      margin-top: 0.6rem;
      font-size: 2.4rem;
      width: 63%;
      margin-bottom: 1.6rem;
    }

    &--desktop {
      display: block;

      @include devices(mobile) {
        display: none;
      }
    }
  }

  &__feats {
    display: flex;
    justify-content: center;
    font-style: italic;
    font-weight: 700;
    font-size: 28px;
    line-height: 133.3%;
    margin-top: 1.7rem;

    @include devices(mobile) {
      flex-direction: column;
      align-items: center;
      font-size: 1.8rem;
    }
  }

  &__feat {
    display: flex;
    align-items: center;
    margin-right: 6rem;

    img {
      margin-right: 1.2rem;

      width: 3rem;
      height: 3rem;
    }

    @include devices(mobile) {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }

  &__form-container {
    display: flex;
    max-width: 80vw;
    margin: 0 auto;
    margin-top: 6.6rem;
    gap: 1.6rem;

    @include devices(mobile) {
      max-width: 90vw;
      margin-top: 2.6rem;
      flex-direction: column;
    }
  }

  &__steps {
    padding: 2.4rem;
    &-container {
      background: #ffffff;
      width: 75%;
      background-color: transparent;

      @include devices(mobile) {
        width: 100%;

        display: none;
      }
    }

    border: 1px solid #e6e6e6;
    border-radius: 12px;

    &--title {
      font-weight: 600;
      font-size: 32px;
      line-height: 133.3%;
    }

    &--step {
      padding: 3.4rem;
      padding-bottom: 0;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__step {
    font-size: 28px;
    line-height: 133.3%;
    margin-bottom: 1.6rem;

    &--active {
      color: #2cac4c;
      font-weight: 700;
    }
  }

  &__form {
    padding: 2.4rem;

    width: 100%;

    background: #ffffff;

    border: 1px solid #e6e6e6;
    border-radius: 12px;

    &--title {
      font-weight: 600;
      font-size: 32px;
      line-height: 133.3%;

      @include devices(mobile) {
        font-weight: 600;
        font-size: 24px;
      }
    }

    &--blurred {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 3.6rem;
      line-height: 26px;

      margin-top: 2.4rem;

      p {
        margin-bottom: 0.8rem;
      }

      img {
        width: 50%;
      }
    }

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      font-size: 24px;
      line-height: 133.3%;
      margin-top: 1.6rem;
      width: 100%;

      @include devices(mobile) {
        font-size: 1.8rem;
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
    margin-right: 1.6rem;

    padding: 1.4rem;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    max-width: 20rem;
    font-size: 24px;
    line-height: 133.3%;

    input[type='radio'] {
      width: 2rem;
      height: 2rem;
      margin-right: 1.2rem;

      &:checked {
        appearance: none;
        -webkit-appearance: none;

        /* For a circular appearance we need a border-radius. */
        border-radius: 50%;

        /* The background will be the radio dot's color. */
        background: #2cac4c;

        /* The border will be the spacing between the dot and the outer circle */
        border: 3px solid #fff;

        /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
        box-shadow: 0 0 0 1px #2cac4c;
      }
    }
  }

  &__label-input {
    width: 48%;

    @include devices(mobile) {
      width: 100%;
      overflow: hidden;
    }
    label {
      display: flex;
      flex-direction: column;
      max-width: 100%;

      select {
        min-width: 30rem;
        margin-top: 1.6rem;
        background: #f1f5f9;
        padding: 1.6rem;
        font-size: 24px;
        line-height: 26px;

        border: 0.4px solid #e6e6e6;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
        border-radius: 4px;

        @include devices(mobile) {
          font-size: 1.8rem;
          min-width: 90%;
        }
      }

      input {
        min-width: 30rem;
        margin-top: 1.6rem;
        background: #f1f5f9;
        border: 0.4px solid #e6e6e6;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 1.6rem;
        font-size: 24px;
        line-height: 26px;

        @include devices(mobile) {
          font-size: 1.8rem;
          min-width: 90%;
        }
      }
    }

    &--step2 {
      width: 100%;

      textarea {
        min-width: 30rem;
        margin-top: 1.6rem;
        background: #f1f5f9;
        border: 0.4px solid #e6e6e6;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 1.6rem;
        font-size: 24px;
        line-height: 26px;
      }
    }
  }

  &__input-flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;

    @include devices(mobile) {
      gap: 1.3rem;
    }
  }

  &__gender {
    display: flex;

    @include devices(mobile) {
      flex-direction: column;
    }
  }

  &__submit-button {
    margin-top: 1.6rem;
  }

  &__stepTwo {
    text-align: center;

    p {
      font-size: 24px;
      line-height: 26px;
      margin-top: 1rem;
      position: relative;

      &::before {
        display: inline-block;
        content: '';
        border-top: 1px solid #f1f5f9;
        width: 7rem;
        margin-right: 0.8rem;
        transform: translateY(-0.5rem);
        position: relative;
      }

      &::after {
        display: inline-block;
        content: '';
        border-top: 1px solid #f1f5f9;
        width: 7rem;
        margin-right: 0.8rem;
        transform: translateY(-0.5rem);
        position: relative;
      }
    }

    h1 {
      font-size: 32px;
      line-height: 38px;
      color: #2cac4c;
      margin-top: 0.8rem;
    }
  }
}
