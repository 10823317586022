.c_us {
  &__linearbg {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(198, 233, 207, 0.08) 24.48%,
      rgba(125, 204, 145, 0.08) 61.46%,
      rgba(44, 172, 76, 0.08) 100%
    );
    padding-bottom: 10rem;

    @include devices(mobile) {
      padding-bottom: 3.6rem;
    }
  }
  &__container {
    max-width: 80vw;
    margin: 0 auto;
  }

  &__heading {
    margin-top: 7.5rem;

    @include devices(mobile) {
      margin-top: 3rem;
    }
  }

  &__ctas {
    display: flex;
    margin-top: 3.6rem;
    gap: 1.6rem;

    @include devices(mobile) {
      flex-direction: column;
    }
  }

  &__cta {
    border-radius: 1.2rem;
    padding: 3.6rem;
    flex-grow: 1;
    border: 1px solid #e6e6e6;

    @include devices(mobile) {
      padding: 2.1rem;
    }

    p {
      font-size: 2.8rem;
      line-height: 3.3rem;

      @include devices(mobile) {
        font-size: 1.8rem;
      }
    }

    a {
      text-decoration: none;
    }
    &:first-child {
      background: #2cac4c;
    }

    @include devices(mobile) {
      text-align: center;
    }

    img {
      @include devices(mobile) {
        width: 4.2rem;
      }
    }
  }

  &__location-form {
    display: flex;
    margin-top: 8rem;

    @include devices(mobile) {
      margin-top: 3.6rem;
      flex-direction: column;
      text-align: center;
    }
  }

  &__location {
    flex-grow: 1;
    p {
      font-size: 2.4rem;

      @include devices(mobile) {
        font-size: 1.8rem;
      }
    }
  }

  &__company {
    &_address {
      max-width: 75%;

      @include devices(mobile) {
        max-width: 100%;
      }
    }

    &_timing {
      font-style: italic;
      font-weight: 500;

      a {
        text-decoration: none;
        color: #333333;
      }
    }

    &_location {
      font-size: 2.4rem;
      font-weight: 600;
      text-decoration: none;
      color: #034ea2;
      flex-grow: 1;
    }
  }

  &__form {
    width: 35%;
    padding: 2.4rem;
    background-color: #ffffff;
    border-radius: 1.2rem;
    border: 1px solid #e6e6e6;
    flex-grow: 1;

    @include devices(mobile) {
      width: 100%;
      padding: 1.5rem;
      text-align: left;
      margin-top: 3.6rem;

      .heading {
        margin-top: 0;
        text-align: left;
        margin-bottom: 1.6rem;
      }
    }

    &--captcha {
      margin-bottom: 2.4rem;
    }

    label {
      font-size: 2.4rem;

      line-height: 2.6rem;
    }

    input,
    textarea {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
      width: 100%;
      padding: 1.6rem;
      background: #f1f5f9;
      /* Primary/Grey2 */

      border: 0.4px solid #e6e6e6;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      font-size: 2rem;
      color: #333333;

      &[type='text'] {
        font-size: 2rem;
      }
    }

    textarea {
      margin-bottom: 2.8rem;
    }
  }

  &__thankyou {
    text-align: center;
  }

  &__heading {
    font-size: 3.2rem;
    line-height: 133%;
    font-weight: 600;
  }
  &__sub {
    font-size: 2.4rem;
    margin-bottom: 3.6rem;
  }
}

.hub {
  &__locations {
    @include devices(mobile) {
      padding-top: 2.4rem;
      margin-top: 1rem;
    }
    max-width: 80vw;
    margin: 0 auto;
    margin-top: 12rem;
    &--cards {
      margin-top: 3.2rem;
      display: flex;
      flex-wrap: wrap;
      gap: 3.2rem;
      margin-bottom: 10rem;
    }

    &--card {
      width: 40rem; // temp
      border: 1px solid #e6e6e6;
      box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.16);
      border-radius: 8px;
      flex-shrink: 0;

      @include devices(mobile) {
        width: 80vw;
      }

      &--image {
        width: 100%;
      }

      &--details {
        padding: 1rem 2.4rem;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 33rem;

        @include devices(mobile) {
          padding: 1.2rem;
          text-align: center;
          height: 26rem;

          button {
            width: 100%;
          }
        }

        h1 {
          font-size: 2.4rem;
          margin-bottom: 1.2rem;
          color: #333333;

          @include devices(mobile) {
            margin-bottom: 0;
            font-size: 2.4rem;
          }
        }
      }

      &--available {
        padding: 0.8rem 1.2rem;
        background-color: rgba(3, 78, 162, 0.12);
        font-style: italic;
        font-size: 1.8rem;
        border-radius: 0.4rem;
        margin-bottom: 1.2rem;
        width: 70%;
        @include devices(mobile) {
          width: 100%;
          text-align: center;
        }
      }

      p {
        font-size: 2rem;
        line-height: 3rem;

        @include devices(mobile) {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      &--map-link {
        color: #034ea2;
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-bottom: 2.4rem;
        margin-top: 1.2rem;

        a {
          color: #034ea2;
          text-decoration: none;
        }

        @include devices(mobile) {
          margin-bottom: 1.2rem;
        }
      }
    }
  }
}
.termsConditions{
  
    margin-top:-31px

   
}