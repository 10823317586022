.nav {
  box-shadow: 0px 0px 8px 0px #3333333D;
  position: relative; 
  z-index: 1;
  @include devices(mobile){
    box-shadow: none;
    z-index: auto;
  }
  &__download {
    display: none;
    background-color: #fef3da;
    padding: 1.6rem;
    justify-content: space-around;
    align-items: center;

    &--text {
      &-main {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }

      &-sub {
        padding-top: 0.4rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808080;
      }
    }

    .button {
      font-size: 1.2rem;
    }

    .button__primary {
      padding: 1rem;
    }
   

    @include devices(mobile) {
      display: flex;
    }
  }

  &__container {
    display: flex;
    max-width: 80vw;
    margin: 0 auto;
    padding: 1.2rem 0;
    justify-content: space-between;
    align-items: center;

    &--menu {
      padding: 1.55rem 0;
    }

    @media screen and (max-width: 1300px) {
      max-width: 90vw;
    }

    @include devices(mobile) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include devices(mobile) {
      display: flex;
      padding: .8rem;

      justify-content: space-between;
      align-items: center;

      &--search {
        margin-left: auto;
      }

      &--item {
        list-style: none;
        padding: 1rem;
        margin-right: 0.8rem;

        &:not(:first-child) {
          border: 0.8px solid #e6e6e6;
          border-radius: 4px;
          display: flex;

          width: 4rem;
          height: 4rem;
        }
        img {
          width: 100%;
          height: 100%;
        }

        &:nth-child(2) {
          margin-left: auto;
        }
      }

      img:not(:first-child) {
        padding: 1rem;
        border-radius: 4px;
      }
    }

    &__sidebar {
      display: flex;
      transform: translateX(120vw);

      @include devices(mobile) {
        // display: none;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.8s ease;
      }
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;

      &--film {
        border: 1px solid;
        position: relative;
        left: 0;
        width: 25vw;
        background-color: #333333;
        opacity: 0.8;
      }
      &--logo {
        padding: 0 2.4rem;
        padding-top: 3.4rem;
      }

      &--container {
        
        width: 75vw;
        display: flex;
        flex-direction: column;
        background-color: white;
        position: relative;
        z-index: 0;

        ul {
          display: flex;
          flex-direction: column;
          padding: 3.3rem 2.4rem;

          list-style: none;
          align-items: flex-start;

          font-size: 2rem;
          font-weight: 500;
          line-height: 2.3rem;
          color: #333333;

          a {
            color: #333333;
            text-decoration: none;
          }

          li {
            margin: 1.2rem;

            // list-style: circle;
          }
        }
      }

      &--footer {
        padding: 2.1rem 2.4rem;

        &--download {
          &-container {
            background-color: #fef3da;
            padding: 1.6rem 2.4rem;
            padding-bottom: 4rem;
          }
          display: flex;
          align-items: center;
          margin-bottom: 1.2rem;

          img {
            width: 2.8rem;
            margin-right: 0.8rem;
          }

          h1 {
            font-weight: 1.6rem;
            line-height: 1.9rem;
            font-weight: 600;
          }

          p {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: #808080;
          }
        }

        &--contact_us {
          display: flex;
        }
      }
    }
  }

  &__left {
    display: flex;
    min-width: 35%;
    max-width: 60%;
    align-items: center;

    a {
      display: inherit;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    margin-left: 2.4rem;
    max-width: 30vw;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    width: 80%;

    input {
      height: 100%;
      border: 0;
      padding: 1rem 1.2rem;
      width: 100%;
      font-size: 1.6rem;

      &:focus {
        outline: none;
      }
    }

    img {
      margin: 1.2rem;
    }
  }

  &__options {
    display: flex;
    list-style: none;
  }

  &__option {
    display: flex;
    padding: 0 2.4rem;
    align-items: center;

    button {
      cursor: pointer;
    }

    &:not(:last-child) {
      border-right: 1px solid #2cac4c;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 1.2rem;

      label {
        font-size: 1.4rem;
        font-weight: 400;
        color: $color-primary-grey;
      }

      &-lang {
        margin-left: 0.3rem;
      }

      select {
        color: #2cac4c;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.9rem;
        border: 0;
        padding: 0;
        background-color: transparent;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }
    &--action {
      color: #2cac4c;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.9rem;
      border: 0;
      padding: 0;
      background-color: transparent;
      text-decoration: none;
    }
  }

  &__menu {
    width: 100%;
    &-item {
      color: #333333;
      font-size: 2rem;
      font-weight: 500;
      margin-left: 5rem;
      background-color: transparent;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #034EA2;
        text-decoration: underline;
      }

      &--dropdown {
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        transform: translateY(-1rem);
        position: absolute;
        margin-top: 1.2rem;
        width: 40vw;
        padding: 3.2rem;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
        border-radius: 11px;

        a {
          text-decoration: none;
        }
      }

      &--lable {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 150%;
        color: #808080;
      }

      &--categories {
        display: flex;
      }
      &--categoriesFeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        gap: 10px;
        
       
      }


      &--category {
        box-sizing: border-box;
        word-wrap: break-word;
        &:hover .nav__menu-item--category--name {
          text-decoration: underline;
        }
        &:hover .nav__menu-item--category--features {
          font-weight: bold;
        }
        &--name {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 150%;
          color: #034EA2;
          margin-top: -5px;
        }

        &--features {
          font-weight:lighter;
          font-size: 1.4rem;
          line-height: 150%;
          color: #808080;
          display: inline-block;
          white-space: normal; 
          overflow: hidden; 
          text-overflow: ellipsis;
        }
      }

      &--playstore {
        background: #F1F5F9; 
       padding-top: 2rem;
       padding-bottom: 1.2rem;
       padding-right: 8rem;
        border-radius: 10px;
        

        &--lable {
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 150%;
          
          color: #808080;
        }

        &--cta {
          justify-content: space-between;
          display: flex; 
          align-items: center; 
       
        }
  
        &--cta > * {
          margin-right: -1.8rem; 
          
        }
      }
    }
  }
}

.h-line {
  border-top: 0.3px solid #f1f5f9;

  @include devices(mobile) {
    display: none;
  }
}

.lineBreak{
  white-space: pre-line;
}

.dropdown {
  position: relative;
  display: inline-block;

}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 5rem;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap; 
  margin-top: 0;
  border: 1.2px solid #E6E6E6;
  border-radius: 10px;
  padding: 0px;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -19px; 
  left: 50px; 
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #2CAC4C transparent;
}

.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -20px; 
  left: 0;
  right: 0;
  height: 20px; 
}


.dropdown-menu a {
  color: black;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
 
}

.dropdown-menu a:hover {
  background-color: transparent;

}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-toggle::after {
  content: "\2304"; 
  font-size: 16px;
  font-weight: 800;
  color: #034ea2;
  margin-left: 7px;
  vertical-align: super; 
  display: inline-block;
  transform: scaleX(1.6);
}


.nav_text {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}

.navLabel{
  font-size: 12px;
  font-weight: 500;
  color: #808080;
  margin-left: 2rem;
}
.navButton{
  background-color: white;
}
.navButton:hover {
  background-color: #EAF7ED;
  border: 0.5px solid  #008120;
}

.nav__mobile__sidebar--menu-item a:hover {
  background-color: #f0f0f0;
  color: #034EA2; 
  text-decoration: underline; 
}

.nav__mobile__sidebar--menu-item .nav_mobile_dropdown {
  list-style: none;
  padding: 0 !important; 
  margin-top: 9px;
}

.nav__mobile__sidebar--menu-item .nav_mobile_dropdown li {
  padding: 0; 
}

.nav__mobile__sidebar--menu-item .nav_mobile_dropdown li a {
  display: block;
  margin-left: -12px;
 
  padding: 0px; 
  text-decoration: none;
  color: #808080;
}

.nav__mobile__sidebar--menu-item .nav_mobile_dropdown li a:hover {
  background-color: #f0f0f0;
  color: #034EA2; 
    text-decoration: underline; 
}
