.partnershipLayout {
    width: auto;
    height: auto;
    top: 13581px;
    left: 34221px;
    background: #FFFFFF;

    @include devices(mobile) {
        width: auto;
        height: auto;
        top: 13581px;
        left: 35788px;

    }

}

.partnershipPage {
    background: url('../../assets/partnershipPage.svg');
    background-repeat: no-repeat;
    background-size: cover;
    

    @include devices(mobile) {
        background: #2CAC4C;
        height: 800px;

    }

    &__container {
        display: grid;
        grid-template-columns: 54% 44%;
        max-width: 80%;
        margin: 0 auto;

        @include devices(mobile) {
            max-width: 86vw;
            grid-template-columns: none;
            text-align: center;
            
        

        }
    }

    &__content {
        margin-top: 4.5rem;
        margin-bottom: 11.6rem;

        @include devices(mobile) {
            margin-top: 3.6rem;
            margin-bottom: 0rem;

            button {
                width: 100%;
                margin-top: 1.6rem;
            }
        }
    }

    &__para {
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 1.6rem;
        font-weight: 100;
        color: white;
        text-align: justify;

        max-width: 497px;
        padding: 1rem;
        box-sizing: border-box;

        @include devices(mobile) {
            text-align: justify;
            max-width: 100%;
            font-size: 18px;
            margin: 0 auto;
        }
    }

    &__paragraph {
        width: 100%;
        margin: 0 auto; 
        font-family: Roboto;
        font-size: 18px; 
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: justify;
        margin-top: 10px; 
        margin-bottom: 20px; 
      
        
        @media (min-width: 768px) {
          font-size: 18px; 
          line-height: 36px;
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
      



    &--image {
        background-image: url('../../assets/partnership_image.svg');
        background-repeat: no-repeat;
        padding: 0rem 1rem 14rem 1rem;
        background-position: 100% 41%;
        background-size: contain;
        margin: 30px;
        height: "554px";
        width: "432px";
        top: "187px";
        left: "838px";
        box-sizing: content-box;

        @include devices(mobile) {
            width: 95%;
            height: 249px;
            margin: 0 auto;
            margin-bottom: 13.6rem;
            
        }
    }   

    &__section {
        padding:5.6rem 16.5rem;
        @include devices(mobile) {

            padding: 2.6rem 2.6rem;
         
        }
        
    }    

    &___btnn {
        width: 286px;
        height: 60px;
        top: 1509px;
        left: 144px;
        padding: 17px 18px;
        border-radius: 8px;
        border: 2px solid #fdfdfd;
        gap: 10px;
        background: white;
        box-shadow: 0px 4px 4px 0px #00000040;
        margin-left: 11px;
    
    
        // Text styling
        color: #2CAC4C;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;

        @include devices(mobile){
            margin: 0 auto;
            height: 52px;
            line-height: 21.09px;
        }
    
    
    }
    &__mainSubheading {
        font-weight: 200;
        font-size: 2.4rem;
        line-height: 3.1rem;
        padding: 10px;
    
        &::before {
            display: inline-block;
            position: absolute;
            content: '';
            border-top: 2px solid #ffffff;
            width: min(10vw, 5rem);
            margin-right: 0.8rem;
            transform: translateY(-0.5rem);
            position: relative;
    
        }
    
        @include devices(mobile) {
            font-size: 1.8rem;
            line-height: 2.1rem;
            text-align: center;
    
            &::after {
                display: inline-block;
                content: '';
                border-top: 2px solid #ffffff;
                width: min(10vw, 5rem);
                margin-left: 0.8rem;
                transform: translateY(-0.5rem);
                position: relative;
    
            }
    
        }
    }
    &__heading {
        font-weight: 500;
        font-size: 48px;
        line-height: 4.4rem;
        color: white;
        padding: 10px;
    
        @include devices(mobile) {
            text-align: center;
            font-size: 24px;
            line-height: 3rem;
            // margin-top: 0.8rem;
        }
    }
    &__paraheading {
        font-weight: 600;
        font-size: 32px;
        line-height: 28.98px;
        margin-top: 1.6rem;
        color: black;
    
        @include devices(mobile) {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
            // width: 320px;
            height: 64px;
            top: 1150px;
            left: 20px;
        }
    }

    &__paraSubheading {
        font-weight: 200;
        font-size: 2.4rem;
        line-height: 3.1rem;
        color: #2CAC4C;
    
        &::before {
            display: inline-block;
            position: absolute;
            content: '';
            border-top: 2px solid #2CAC4C;
            width: min(10vw, 5rem);
            margin-right: 0.8rem;
            transform: translateY(-0.5rem);
            position: relative;
    
        }
    
        @include devices(mobile) {
            font-size: 1.8rem;
            line-height: 2.1rem;
            text-align: center;
    
            &::after {
                display: inline-block;
                content: '';
                border-top: 2px solid #2CAC4C;
                width: min(10vw, 5rem);
                margin-left: 0.8rem;
                transform: translateY(-0.5rem);
                position: relative;
    
            }
    
        }
    }
    

    &__subSectionHeading {
        font-weight: 600;
        font-size: 32px;
        line-height: 28.98px;
        margin-top: 75px;
        color: black;
    
        @include devices(mobile) {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 96px;
            letter-spacing: 0px;
            text-align: center;
            height: 64px;
            top: 1150px;
            left: 20px;
            margin-top: 5px;
        }
        &__image {
            width: 100%;
            border-radius: 8px;
            border: 4px solid #E6E6E6;
            margin-top: 30px;
          
      
          @include devices(mobile) {
            width: 100%;
            height: auto;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }

        }
    }


}



.btn-container {
    display: flex;
    align-items: center;

    @include devices(mobile) {
        flex-direction: column;
    }
}

.___button {
    width: 286px;
    height: 60px;
    top: 1509px;
    left: 144px;
    padding: 17px 18px;
    border-radius: 8px;
    border: 2px solid #2CAC4C;
    gap: 10px;
    background: #2CAC4C;
    box-shadow: 0px 4px 4px 0px #00000040;


    // Text styling
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;

    @include devices(mobile) {
        width: 100%;
        height: 52px;
        top: 1979px;
        left: 20px;
        padding: 16px, 24px, 16px, 24px;
        border-radius: 8px;
        border: 1px solid #2CAC4C;
        gap: 12px;
        line-height: 21.09px;
        font-size: 18px;
        margin-top: 19px;

    }


}

.btntext {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #2CAC4C;
    margin-left: 20px;

    &__img {
        width: 12.38px;
        height: 12.75px;
        margin-left: 6px;
        border: 1.6px;
        top: 15.19x;
        left: 6.19px;
    }

    @include devices(mobile) {
        margin-left: 0;
        margin-top: 12px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }
}

.partnership-footer {
   
    width: 100%;
    height: 201px;
    top: 3132px;
    background-color: #2CAC4C;
    margin-top: 50px;

    @include devices(mobile) {
        width: 100%;
        height: 151px;
        top: auto;
        margin: 0 auto;
    }
    &__footerText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 38px;
        font-weight: 600;
        line-height: 85px;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
        
        
    
        &__btn {
            width: 286px;
            height: 55px;
            margin-top: 10px;
            margin-bottom: 50px;
            padding: 17px 18px;
            border-radius: 8px;
            border: 2px solid #ffffff;
            gap: 10px;
            background-color: #FFFFFF;
            box-shadow: 0px 4px 4px 0px #00000040;
    
            // Text styling
            color: #2CAC4C;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: center;

            @include devices(mobile) {
                margin: 0 auto;
                margin-top: 10px;
                height: 52px;
                line-height: 16.09px;
                width: 100%;
            }


        }
    
        @include devices(mobile) {
            font-size: 22px;
            line-height: 29px;
    
        }

}




}



